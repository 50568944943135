html {
    scroll-behavior: smooth;
}

.home{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: 'background';
}